var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "keyword", label: "输入:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "270px" },
                    attrs: {
                      clearable: "",
                      placeholder: "代理商名称或电话关键词"
                    },
                    model: {
                      value: _vm.searchForm.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "keyword", $$v)
                      },
                      expression: "searchForm.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("searchForm")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "batch-container" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("代理")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleTree } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showTree,
                      expression: "!showTree"
                    }
                  ]
                },
                [_vm._v("代理架构")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTree,
                      expression: "showTree"
                    }
                  ]
                },
                [_vm._v("代理列表")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTree,
              expression: "showTree"
            }
          ]
        },
        [
          _c("ve-tree", {
            ref: "treeChart",
            attrs: { data: _vm.chartData, settings: _vm.chartSettings }
          })
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showTree,
              expression: "!showTree"
            }
          ],
          staticClass: "table-container"
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.dataList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "名称",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contact",
                  label: "联系方式",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "level",
                  label: "级别",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.transUserLevel(scope.row.level)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "area",
                  label: "区域",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "负责人",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.personincharge.length
                          ? _c(
                              "div",
                              _vm._l(scope.row.personincharge, function(item) {
                                return _c("div", { key: item.id }, [
                                  _vm._v(
                                    _vm._s(item.mobile + "/" + item.nickname)
                                  )
                                ])
                              }),
                              0
                            )
                          : _c("div", [_vm._v("无")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-opera" },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "编辑", placement: "top" } },
                              [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEdit(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "edit" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "span",
                              { staticClass: "m-l-8" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: { content: "删除", placement: "top" }
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "delete" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.handleDel(scope.row)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0 && !_vm.showTree,
            expression: "totalPage>0 && !showTree"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.getTableData
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.agentDialog,
            title: _vm.textMap[_vm.dialogTitle]
          },
          on: {
            "update:visible": function($event) {
              _vm.agentDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                "label-position": "right",
                "label-width": "130px",
                model: _vm.dialogForm,
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.dialogForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "name", $$v)
                      },
                      expression: "dialogForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式：", prop: "contact" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入联系方式" },
                    model: {
                      value: _vm.dialogForm.contact,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "contact", $$v)
                      },
                      expression: "dialogForm.contact"
                    }
                  })
                ],
                1
              ),
              _vm.dialogTitle === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上级代理商：", prop: "parent_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入手机号或名字搜索",
                            "remote-method": _vm.remoteAgent,
                            clearable: "",
                            loading: _vm.agentLoading
                          },
                          model: {
                            value: _vm.dialogForm.parent_id,
                            callback: function($$v) {
                              _vm.$set(_vm.dialogForm, "parent_id", $$v)
                            },
                            expression: "dialogForm.parent_id"
                          }
                        },
                        _vm._l(_vm.agentOptions, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.name))
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "13px"
                                  }
                                },
                                [_vm._v(_vm._s(item.contact))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "负责人：", prop: "member_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        multiple: "",
                        "reserve-keyword": "",
                        placeholder: "请输入手机号或名字搜索",
                        "remote-method": _vm.remoteVillage,
                        clearable: "",
                        loading: _vm.villageLoading
                      },
                      model: {
                        value: _vm.dialogForm.member_id,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogForm, "member_id", $$v)
                        },
                        expression: "dialogForm.member_id"
                      }
                    },
                    _vm._l(_vm.villageOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: {
                            label: item.nickname || item.mobile,
                            value: item.id
                          }
                        },
                        [
                          _c("span", { staticClass: "text-left" }, [
                            _vm._v(_vm._s(item.nickname))
                          ]),
                          _c("span", { staticClass: "remote-select_right" }, [
                            _vm._v(_vm._s(item.mobile))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域：", prop: "area" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.dialogForm.area,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "area", $$v)
                      },
                      expression: "dialogForm.area"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-container" },
            [
              _vm.dialogTitle === "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm.dialogTitle === "update"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleEditBtn }
                    },
                    [_vm._v("修改")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.agentDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }