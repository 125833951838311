import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchAgentList, addAgent, updateAgent, delAgent, fetchAgentTree } from "@/api/charge";
import { fetchMemberList } from "@/api/member";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
import { validateMobile } from "@/utils/validate";
import { transUserLevel } from "@/utils/util";
import VeTree from "v-charts/lib/tree.common";
import "echarts/lib/component/toolbox";
export default {
  name: "metterList",
  components: {
    Pagination: Pagination,
    VeTree: VeTree
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: ""
      },
      gradeOptions: [],
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      pickerOptions: _global.timeOption,
      agentDialog: false,
      dialogForm: {
        name: "",
        contact: "",
        parent_id: "",
        member_id: [],
        area: "",
        id: ""
      },
      textMap: {
        update: "编辑代理",
        create: "添加代理"
      },
      dialogTitle: "",
      agentOptions: [],
      agentLoading: false,
      villageLoading: false,
      villageOptions: [],
      rules: {
        name: [{
          required: true,
          message: "名称不能为空"
        }],
        member_id: [{
          required: true,
          message: "负责人不能为空",
          trigger: "blur"
        }],
        area: [{
          required: true,
          message: "区域不能为空",
          trigger: "blur"
        }],
        contact: [{
          required: true,
          validator: validateMobile
        }]
      },
      chartData: {
        columns: ["name", "value"],
        rows: []
      },
      showTree: false,
      chartSettings: {
        seriesMap: {
          trees: {
            orient: "horizontal",
            // vertical horizontal
            nodePadding: 8,
            // hoverable: true,
            roam: true,
            symbolSize: 15,
            initialTreeDepth: -1,
            top: "30%",
            layerPadding: 120,
            hoverable: false,
            itemStyle: {
              normal: {
                color: "#4883b4",
                borderColor: "#409efe",
                label: {
                  show: true,
                  position: "top",
                  // rotate: -30,
                  formatter: "{b}",
                  textStyle: {
                    color: "#000",
                    fontSize: 15
                  }
                },
                lineStyle: {
                  color: "#aaa",
                  type: "solid",
                  // 'curve'|'broken'|'solid'|'dotted'|'dashed'
                  curveness: 0
                }
              },
              emphasis: {
                color: "#4883b4",
                label: {
                  show: false
                },
                borderWidth: 0
              }
            }
          }
        }
      }
    };
  },
  created: function created() {
    this.getTableData(); // this.getAgentTree()
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchAgentList(data).then(function (response) {
        console.log(response);
        _this.tableLoading = false;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      console.log("提交添加代理");
      console.log(this.dialogForm);
      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this2.dialogForm);
          var memberId = data.member_id.join(",");
          data.member_id = memberId;
          addAgent(data).then(function (res) {
            console.log(res); // 成功之后刷新或
            // this.dataList.unshift('返回的数据')

            _this2.$message({
              type: "success",
              message: "添加代理成功"
            });

            _this2.agentDialog = false;

            _this2.getTableData();
          }).catch(function (err) {
            console.log(err, "fuc");

            _this2.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleEditBtn: function handleEditBtn() {
      var _this3 = this;

      console.log("编辑代理");
      console.log(this.dialogForm);
      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this3.dialogForm);
          var memberId = data.member_id.join(",");
          data.member_id = memberId;
          updateAgent(data).then(function (res) {
            console.log(res); // 成功之后刷新或
            // this.dataList.unshift('返回的数据')

            _this3.$message({
              type: "success",
              message: "编辑代理成功"
            });

            _this3.agentDialog = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleEdit: function handleEdit(row) {
      console.log(row);
      var data = Object.assign({}, row);
      this.dialogTitle = "update";
      this.agentDialog = true;
      var arr = [];
      var list = data.personincharge;

      for (var i in list) {
        arr.push(list[i]["id"]);
      }

      this.villageOptions = list;
      this.dialogForm = {
        name: data.name,
        contact: data.contact,
        member_id: arr,
        area: data.area,
        id: data.id
      };
    },
    resetAgent: function resetAgent() {
      this.dialogForm = {
        name: "",
        contact: "",
        parent_id: "",
        member_id: [],
        area: ""
      };
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      this.resetAgent();
      this.agentDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this4.$refs["dialogForm"].clearValidate();
      });
    },
    remoteAgent: function remoteAgent(query) {
      var _this5 = this;

      console.log("agent remote");

      if (query !== "") {
        this.agentLoading = true;
        setTimeout(function () {
          var data = {
            keyword: query
          };
          fetchAgentList(data).then(function (res) {
            console.log(res);
            _this5.agentLoading = false;
            _this5.agentOptions = res.data;
          }).catch(function (err) {
            _this5.agentLoading = false;
          });
        }, 100);
      } else {
        this.agentOptions = [];
      }
    },
    remoteVillage: function remoteVillage(query) {
      var _this6 = this;

      console.log("village remote");

      if (query !== "") {
        this.villageLoading = true;
        setTimeout(function () {
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this6.villageLoading = false;
            _this6.villageOptions = res.data;
          }).catch(function (err) {
            _this6.villageLoading = false;
          });
        }, 100);
      } else {
        this.villageOptions = [];
      }
    },
    handleDel: function handleDel(row) {
      var _this7 = this;

      console.log("删除代理");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u4EE3\u7406\u5546 ".concat(row.name, "?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        confirmButtonClass: ""
      }).then(function () {
        var id = row.id;
        delAgent(id).then(function (res) {
          console.log(res);

          _this7.$message({
            type: "success",
            message: "删除代理成功"
          });

          _this7.getTableData(); // const index = this.dataList.indexOf(row)
          // this.dataList.splice(index, 1)

        }).catch(function (err) {
          _this7.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    getAgentTree: function getAgentTree() {
      var _this8 = this;

      this.chartData.rows = [];
      fetchAgentTree().then(function (res) {
        var obj = {
          name: "trees",
          value: [res.data]
        };
        console.log(res.data);

        _this8.chartData.rows.push(obj);
      });
    },
    handleTree: function handleTree() {
      var _this9 = this;

      this.showTree = !this.showTree;

      if (this.showTree) {
        this.getAgentTree();
        this.$nextTick(function (_) {
          _this9.$refs["treeChart"].echarts.resize();
        });
      }
    },
    transUserLevel: transUserLevel
  }
};